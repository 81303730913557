<template>
  <div> 
    <div class="panel-body row">
      
      <div class="col-md-12 " style="margin-bottom:5px;">
        <div>
          <h4>Average Order Value</h4>
        </div>
        
      </div>
      <div class="col-md-6" >
        <div style="padding: 10px; display:block; " >
            <dashbord-cards
              v-if="showAvgOrderChart1"
              :charttype="cards[0].charttype"
              :chartdata="cards[0].chartdata"
              :type="cards[0].type"
              :cardBg="cards[0].cardBg"
              :name="cards[0].name"
              :value="cards[0].value"
              :icon="cards[0].icon"
              :styleChart="'auto'"
              @setDuration="getTotalAvgOrderValChartData"
            />
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12" id="preview-area"  v-if="!showAvgOrderChart1">
          <div class="row text-center">
              <div class="sk-chase">
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
              </div>
          </div>
        </div>  
      </div>
      <div class="col-md-6">
        <div style="padding: 10px; display:block; ">
          <dashbord-cards
            v-if="showAvgOrder2"
            :charttype="cards[1].charttype"
            :chartdata="cards[1].chartdata"
            :type="cards[1].type"
            :cardBg="cards[1].cardBg"
            :name="cards[1].name"
            :value="cards[1].value"
            :icon="cards[1].icon"
            :styleChart="'auto'"
            @setDuration="getHighestOrderData"
          />
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12" id="preview-area"  v-if="!showAvgOrder2">
          <div class="row text-center">
              <div class="sk-chase">
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
              </div>
          </div>
        </div>  
      </div>
      <div class="col-md-6" id="lowchart">
        <div style="padding: 10px; display:block; ">
          <dashbord-cards
            v-if="showAvgOrder3"
            :charttype="cards[2].charttype"
            :chartdata="cards[2].chartdata"
            :type="cards[2].type"
            :cardBg="cards[2].cardBg"
            :name="cards[2].name"
            :value="cards[2].value"
            :icon="cards[2].icon"
            :styleChart="'auto'"
            @setDuration="setchart3"
          />
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12" id="preview-area"  v-if="!showAvgOrder3 && showAvgOrderChart1">
          <div class="row text-center">
              <div class="sk-chase">
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
              </div>
          </div>
        </div>  
      </div>
      </div>
    </div>
</template>
<script>
import DashbordCards from '@/components/common/Dashbord/DashbordCards';
import mixinDashboard from "@/mixins/merchant/APIDashboard";
export default {
  mixins: [mixinDashboard],
   components: { 
     DashbordCards
   },
  data: function () {
    return {
      scrol: false,
      breadcrumb: [
          {
              text: "Dashboard",
              href: "/merchant-dashbord",
              active: false
          },
          {
              text: "Average Order Value",
              active: true
          }
      ],
      showAvgOrderChart1: true,
      showAvgOrder2: true,
      showAvgOrder3: true,
      selected_option: 1,
      cards:[
        {
          cardBg: "background-color: #ffffff !important; color: #6a6a6a !important; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important; border: white;",
          name: "Average Order Value",
          value: "",
          icon: "fa fa-ticket",
          type: "single-view",
          charttype: 'DynamicChartLine',
          chartdata: {
            labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
            datasets: [
              {
                label: 'Card',
                backgroundColor: '#005baa',
                borderColor: "#005baa",
                data: [],
                fill: false
              }, 
            ]
          }
         },
        {
          cardBg: "background-color: #ffffff !important; color: #6a6a6a !important; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important; border: white;",
          name: "Highest Order Value",
          value: "",
          icon: "fa fa-ticket",
          type: "single-view",
          charttype: 'DynamicChartLine',
          chartdata: {
            labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
            datasets: [
              {
                label: 'Card',
                backgroundColor: '#005baa',
                borderColor: "#005baa",
                data: [],
                fill: false
              }, 
            ]
          }
        },
        {
          cardBg: "background-color: #ffffff !important; color: #6a6a6a !important; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important; border: white;",
          name: "Lowest Order Value",
          value: "",
          icon: "fa fa-ticket",
          type: "single-view",
          charttype: 'DynamicChartLine',
          chartdata: {
            labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
            datasets: [
              {
                label: 'Card',
                backgroundColor: '#005baa',
                borderColor: "#005baa",
                data: [],
                fill: false
              }, 
            ]
          }
        }
      ]
    }
  },
  watch: {},
  created(){
    this.handleBreadcrumb();
    this.getTotalAvgOrderValChartData(1);
    this.getHighestOrderData(1);
    this.getLowesetOrderData(1);
  },
  methods: {
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    setchart3: function(id) {
      this.scrol= true;
      this.getLowesetOrderData(id);
    },
    getTotalAvgOrderValChartData: async function(id) {
      try {
        this.showAvgOrderChart1 = false;
        let response = await this.getTotalAvgOrderValApi(id);
        if (response) {
          this.cards[0].value = response.count.toFixed(0) + "";
          response.chart_data.reverse();
          this.cards[0].chartdata.labels = [];
          this.cards[0].chartdata.datasets[0].data=[];
          response.chart_data.forEach(element => {
            this.cards[0].chartdata.labels.push(
              id==1?"Day "+element._id:id==2? "Week "+element._id: id== 3 ? "Month "+element._id : ""
            );
            this.cards[0].chartdata.datasets[0].data.push( element.avgValue);
          });
        }
        this.showAvgOrderChart1 = true;
      } catch (error) {
        this.showAvgOrderChart1 = true;
        throw(error);
      }
    },
    getHighestOrderData: async function(id) {
      try {
        this.showAvgOrder2 = false;
        let response = await this.getHighestOrderDataApi(id);
        if(response){
          this.cards[1].value = response.count + "";
            response.chart_data.reverse();
            this.cards[1].chartdata.labels= [];
            this.cards[1].chartdata.datasets[0].data = [];
          response.chart_data.forEach(element => {
            this.cards[1].chartdata.labels.push(
              id==1?"Day "+element._id:id==2? "Week "+element._id: id== 3 ? "Month "+element._id : ""
            );
            this.cards[1].chartdata.datasets[0].data.push( element.maxValue);
          });
        }
        this.showAvgOrder2 = true;
      } catch (error) {
        this.showAvgOrder2 = true;
        throw(error); 
      }
    },
    getLowesetOrderData: async function(id) {
      try {
        this.showAvgOrder3 = false;
        let response = await this.getLowesetOrderDataApi(id);
        if(response){
          this.cards[2].value = response.count + "";
          response.chart_data.reverse();
          this.cards[2].chartdata.labels = [];
          this.cards[2].chartdata.datasets[0].data = [];
          response.chart_data.forEach(element => {
            this.cards[2].chartdata.labels.push(
              id==1?"Day "+element._id:id==2? "Week "+element._id: id== 3 ? "Month "+element._id : ""
            );
            this.cards[2].chartdata.datasets[0].data.push( element.minValue);
          });
        }
        this.showAvgOrder3 = true;
        if(this.scrol)
          document.getElementById('lowchart').scrollIntoView();
      } catch (error) {
        this.showAvgOrder3 = true;
        throw(error); 
      }
    }
  }
}
</script>
<style >
#chart-bar-0 {
    height: 550px!important;
}
.avatar-style {
  width: 100px;
  text-align: center;
}
.img-avatar,
.avatar > img,
.img-circle {
  vertical-align: middle;
}
.action-style {
  width: 265px;
}
.head-table {
  background: white !important;
  text-align: left;
}
.table-border-out {
    border: 1px solid #ddd;
}
</style>